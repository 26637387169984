/**
 * External Dependencies
 */
// import 'jquery';
// import 'bootstrap';

// $(document).ready(() => {
//   // console.log('Hello world');
// });

var test = ['test', 'test2'];
console.log(test);
